export const languagesAvailable = {
	id: 'LearningObject_LoDetails_LanguagesAvailable',
	defaultMessage: 'Languages Available',
};

export const languagesAvailableDescription = {
	id: 'LearningObject_LoDetails_LanguagesAvailableDescription',
	defaultMessage:
		'This training is also available in other languages. Click the links below to register for this training in your preferred language.',
};

export const modules = {
	id: 'LearningObject_LoDetails_Modules',
	defaultMessage: 'Modules',
};

export const about = {
	id: 'LearningObject_LoDetails_About',
	defaultMessage: 'About',
};

export const where = {
	id: 'LearningObject_LoDetails_Where',
	defaultMessage: 'Where',
};

export const language = {
	id: 'LearningObject_LoDetails_Language',
	defaultMessage: 'Language',
};

export const starts = {
	id: 'LearningObject_LoDetails_Starts',
	defaultMessage: 'Starts',
};

export const ends = {
	id: 'LearningObject_LoDetails_Ends',
	defaultMessage: 'Ends',
};

export const duration = {
	id: 'LearningObject_LoDetails_Duration',
	defaultMessage: 'Duration',
};

export const offeredBy = {
	id: 'LearningObject_LoDetails_OfferedBy',
	defaultMessage: 'Offered By',
};

export const offeredByAndSoldBy = {
	id: 'LearningObject_LoDetails_OfferedBy_And_SoldBy',
	defaultMessage: '{trainingProvider}',
};

export const virtualLocation = {
	id: 'SessionSearch_Card_VirtualLocation',
	defaultMessage: 'Virtual Location',
};

export const disclaimerPrivateIlt = {
	id: 'LearningObject_LoDetails_DisclaimerPrivateTraining',
	defaultMessage:
		'This is a private, paid AWS instructor-led training class reserved for specific customers, not offered for free or to the general public. ' +
		'If your employer asked you to take this class, please proceed to registration. If you don’t know whether you were asked to take this class, ' +
		'please reach out to your employer, or contact <u><a href="https://support.aws.amazon.com/#/contacts/aws-training">AWS Customer Support</a></u>.',
};
