import PropTypes from 'prop-types';
import React from 'react';
import { Card, PalomaDesignSystem } from '@amzn/awspaloma-ui';
import { FormattedMessage, injectIntl } from 'react-intl';

import FormattedAddress from '../FormattedAddress';
import LEARNING_OBJECT_KINDS, {
	getKindTheme,
} from '../../utils/learningObjectKinds';
import styles from './SessionSearch.card.styles';
import { OFFERED_BY_AWS } from './SessionSearch.enums';
import { getNestedProperty } from '../../utils/lambda';
import { intlShape } from '../../modules/Localization/util';

/**
 * Displays a session search card in the session search results page
 *
 * @param {number} resellingPlatform The name of the reselling platform of the ILT or VILT session. As of 2020-07-14, this can only be 0 ('None') or 1 ('Xvoucher').
 */
const SessionSearchCard = ({
	currency,
	detailsPage,
	displayKind,
	displayStartDateTime,
	duration,
	id,
	intl,
	kind,
	resellingPlatform,
	language,
	location,
	offeredBy,
	price,
	sessionBuildingName,
	sessionLocationDisplayTitle,
}) => {
	const hasPhysicalLocation = kind === LEARNING_OBJECT_KINDS.IltSession;
	const kindTheme = getKindTheme(kind);
	const timezone = getNestedProperty(location, 'timezone', 'displayName');

	/**
	 * Indicates whether the user should receive the Xvoucher payment experience or not
	 *
	 * @returns {boolean} Returns {@code true} if it is appropriate to show the Xvoucher payment experience for the LO
	 */
	const shouldShowXvoucher = (
		price,
		kind,
		currencyCode,
		resellingPlatform,
		trainingProvider,
	) => {
		return true; //Part of deprecating Amazon Pay
	};

	// If this course is offered by AWS, we should show the LO Details Page
	if (offeredBy.id === OFFERED_BY_AWS)
		detailsPage = `/learningobject/ilt?id=${id}`;

	return (
		<a href={detailsPage} data-testid="linkWrapper">
			<Card gradientName={kindTheme.gradientName} role="link">
				<Card.Header>
					{hasPhysicalLocation ? (
						<span>{`${location.city.name}, ${location.city.country.abbreviation}`}</span>
					) : (
						<FormattedMessage
							id="SessionSearch_Card_VirtualLocation"
							defaultMessage="Virtual Location"
						/>
					)}
					<span className={styles.startTime}>{displayStartDateTime}</span>
				</Card.Header>

				<Card.Metadata>
					<Card.Tag
						fullWidth
						color={PalomaDesignSystem.get(kindTheme.color)}
						className={styles.tag}
					>
						{displayKind}
					</Card.Tag>
					<Card.Tag className={styles.tag}>{duration.displayString}</Card.Tag>
					<Card.Tag className={styles.tag}>{language.name}</Card.Tag>

					{timezone ? (
						<Card.Tag className={styles.tag} float="right">
							<FormattedMessage
								id="SessionSearch_Card_TimeZone"
								defaultMessage="Time zone: {zone}"
								values={{
									zone: timezone,
								}}
							/>
						</Card.Tag>
					) : null}
				</Card.Metadata>

				<Card.Description>
					<span className={styles.descSection}>
						{shouldShowXvoucher(
							price,
							kind,
							currency.code,
							resellingPlatform,
							offeredBy.id,
						) ? (
							<FormattedMessage
								id="SessionSearch_Card_OfferedBy_And_SoldBy"
								defaultMessage="Offered by: {trainingProvider}"
								values={{
									trainingProvider: offeredBy.name,
								}}
							/>
						) : (
							<FormattedMessage
								id="SessionSearch_Card_OfferedBy"
								defaultMessage="Offered by: {name}"
								values={{
									name: offeredBy.name,
								}}
							/>
						)}
					</span>
					{hasPhysicalLocation ? (
						<span className={styles.descSection}>
							<FormattedAddress
								location={location}
								buildingName={sessionBuildingName}
								locationDisplay={sessionLocationDisplayTitle}
							/>
						</span>
					) : null}
				</Card.Description>
			</Card>
		</a>
	);
};

SessionSearchCard.propTypes = {
	/**
	 * Internationlization
	 */
	intl: intlShape.isRequired,

	/**
	 * Session props
	 */
	currency: PropTypes.shape({
		code: PropTypes.string.isRequired,
	}).isRequired,
	detailsPage: PropTypes.string,
	displayKind: PropTypes.string.isRequired,
	displayStartDateTime: PropTypes.string.isRequired,
	duration: PropTypes.shape({
		displayString: PropTypes.string.isRequired,
	}).isRequired,
	id: PropTypes.number.isRequired,
	kind: PropTypes.number.isRequired,
	language: PropTypes.shape({
		name: PropTypes.string.isRequired,
	}).isRequired,
	location: PropTypes.shape({
		city: PropTypes.shape({
			name: PropTypes.string.isRequired,
			country: PropTypes.shape({
				abbreviation: PropTypes.string.isRequired,
			}).isRequired,
		}),
		timezone: PropTypes.shape({
			displayName: PropTypes.string,
		}),
	}).isRequired,
	offeredBy: PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
	price: PropTypes.number,
	resellingPlatform: PropTypes.number.isRequired,
	sessionBuildingName: PropTypes.string,
	sessionLocationDisplayTitle: PropTypes.string,
};

SessionSearchCard.defaultProps = {
	detailsPage: null,
};

export default injectIntl(SessionSearchCard);
