import React, { Fragment, ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import FormattedAddress from '../../FormattedAddress';
import camelCase from '../../../utils/camelCase';
import {
	duration,
	ends,
	language,
	offeredBy,
	offeredByAndSoldBy,
	starts,
	virtualLocation,
	where,
} from './LoDetails.intl';
import {
	isAuthorizedResellingPlatform,
	isKindVilt,
	isUsdCurrency,
	paymentApplicable,
} from '../../../utils/learningObject';
import { defaultTextStyles, getLoKindHeaderStyles } from './LoDetails.styles';
import { selectors as appSelectors } from '../../App/App.module';
import { DefaultRootState, useSelector } from 'react-redux';
import { LearningObjectKind, TrainingProvider } from '../../../lib/enums';
import { Location } from '../../FormattedAddress/FormattedAddress';

export interface IltDetailsProps {
	isVilt: boolean;
	location: Location;
	startDate: string;
	endDate: string;
	loLanguage: string;
	totalDuration: string;
	buildingName?: string;
	locationDisplay?: string;
	resellingPlatform: number;
	currencyCode: string;
	price: number;
	shouldHideLocation?: boolean;
}

/**
 * Displays details for the ILT or VILT session supplied.
 *
 * @param location The location object with information about the ILT session.
 * @param loLanguage The language of the ILT session.
 * @param startDate The start date of the ILT session.
 * @param endDate The end date of the ILT session.
 * @param totalDuration The total duration of the ILT session.
 * @param resellingPlatform The name of the reselling platform of the ILT or VILT session. As of 2020-07-14, this can only be 0 ('None') or 1 ('Xvoucher').
 */
const IltDetails = ({
	isVilt,
	location,
	loLanguage,
	startDate,
	endDate,
	totalDuration,
	buildingName,
	locationDisplay,
	resellingPlatform,
	currencyCode,
	price,
	shouldHideLocation,
}: IltDetailsProps): ReactElement => {
	location = camelCase(location) as Location;
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const timezone = location.timezone!.displayName;
	const amazonWebServices = 'Amazon Web Services';
	const isXvoucherDisabled = useSelector(
		appSelectors.isFeatureDisabled('Xvoucher') as (
			state: DefaultRootState,
		) => unknown,
	) as boolean; // Att: Normally, feature flags are used to disable/enable full routes in App.tsx. Here we are using it to trigger a component instead.

	/**
	 * Indicates whether the user should receive the Amazon Pay experience or not
	 *
	 * @returns Returns {@code true} if it is appropriate to show the Amazon Pay experience for the LO
	 */
	const shouldShowAmazonPay = (
		price: number,
		kind: number,
		currencyCode: string,
		resellingPlatform: number,
		trainingProvider: number,
	): boolean => {
		if (!paymentApplicable(price, trainingProvider)) {
			return false;
		}
		if (!isKindVilt(kind)) {
			return true;
		}
		if (!isUsdCurrency(currencyCode)) {
			return false;
		}
		if (!isAuthorizedResellingPlatform(resellingPlatform)) {
			return true;
		}
		return isXvoucherDisabled;
	};

	/**
	 * Indicates whether the user should receive the Xvoucher payment experience or not
	 *
	 * @returns Returns {@code true} if it is appropriate to show the Xvoucher payment experience for the LO
	 */
	const shouldShowXvoucher = (
		price: number,
		kind: number,
		currencyCode: string,
		resellingPlatform: number,
		trainingProvider: number,
	): boolean => {
		if (!paymentApplicable(price, trainingProvider)) {
			return false;
		}
		return !shouldShowAmazonPay(
			price,
			kind,
			currencyCode,
			resellingPlatform,
			trainingProvider,
		);
	};

	return (
		<Fragment>
			<h3
				className={getLoKindHeaderStyles(2)}
				data-testid="IltDetailsHeaderWhere"
			>
				<FormattedMessage {...where} />
			</h3>
			<p className={defaultTextStyles}>
				{isVilt ? (
					<FormattedMessage {...virtualLocation} />
				) : (
					<FormattedAddress
						location={location}
						buildingName={buildingName}
						locationDisplay={locationDisplay}
						shouldHideLocation={shouldHideLocation}
					/>
				)}
			</p>

			<h3
				className={getLoKindHeaderStyles(2)}
				data-testid="IltDetailsHeaderLanguage"
			>
				<FormattedMessage {...language} />
			</h3>
			<p className={defaultTextStyles}>{loLanguage}</p>

			<h3
				className={getLoKindHeaderStyles(2)}
				data-testid="IltDetailsHeaderDuration"
			>
				<FormattedMessage {...duration} />
			</h3>
			<p className={defaultTextStyles}>{totalDuration}</p>

			<h3
				className={getLoKindHeaderStyles(2)}
				data-testid="IltDetailsHeaderStarts"
			>
				<FormattedMessage {...starts} />
			</h3>
			<p className={defaultTextStyles}>
				{startDate}
				<br />
				{timezone}
			</p>

			<h3
				className={getLoKindHeaderStyles(2)}
				data-testid="IltDetailsHeaderEnds"
			>
				<FormattedMessage {...ends} />
			</h3>
			<p className={defaultTextStyles}>
				{endDate}
				<br />
				{timezone}
			</p>

			<h3
				className={getLoKindHeaderStyles(2)}
				data-testid="IltDetailsHeaderProvider"
			>
				<FormattedMessage {...offeredBy} />
			</h3>

			<p data-testid="IltDetailsOfferedBy" className={defaultTextStyles}>
				{shouldShowXvoucher(
					price,
					isVilt
						? LearningObjectKind.VirtualIltSession
						: LearningObjectKind.IltSession,
					currencyCode,
					resellingPlatform,
					TrainingProvider.AmazonWebServices,
				) ? (
					<FormattedMessage
						{...offeredByAndSoldBy}
						values={{
							trainingProvider: amazonWebServices,
						}}
					/>
				) : (
					amazonWebServices
				)}
			</p>
		</Fragment>
	);
};

export default IltDetails;
