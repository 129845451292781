import React, {
	Fragment,
	ReactElement,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	currencyCodes,
	LearningObjectKind,
	ResellingPlatform,
} from '../../lib/enums';
import { isNumber } from '../../utils/types';

// @ts-ignore
import Script from 'react-load-script';
import get from 'lodash.get';
import { FormattedMessage, useIntl } from 'react-intl';
// @ts-ignore
import { Button, Loader, LoaderConfig } from '@amzn/awspaloma-ui';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';

import LEARNING_OBJECT_KINDS from '../../utils/learningObjectKinds';
import actions from './state/Checkout.actions';
import styles from './Checkout.payment.styles';
import {
	checkoutAlertAction,
	CheckoutAlertCategories,
	CheckoutAlertLevels,
} from './state/Checkout.alert';
import { selectors as appSelectors } from '../App/App.module';
import {
	currentAccountMessage,
	estimatedTaxHelp,
	makePaymentButton,
	registerButton,
	signInButton,
	walletPaymentMethod,
	xvoucherEstimatedTaxHelp,
	xvoucherProceedToPayment,
	xvoucherRedirect,
} from './Checkout.intl';
import {
	ApiCaptureOrder,
	ApiPwaConfig,
	AwsSession,
	CartItem,
	PaymentState,
	PaymentStatusCode,
	SellerOfRecord,
} from './Checkout.utils';
import {
	audienceRoot as audienceRootSelector,
	capturedOrder as capturedOrderSelector,
	isFullDiscount as isFullDiscountSelector,
	isFullDiscountCheckoutService as isFullDiscountSelectorForCheckoutService,
	isLoadingKey as isLoadingKeySelector,
	isPwaReady as isPwaReadySelector,
	language as languageSelector,
	login as loginSelector,
	pwaConfig as pwaConfigSelector,
	state as stateSelector,
	isVoucherInvalid as isVoucherInvalidSelector,
} from './state/Checkout.selectors';
import {
	AMAZON_PAY_AUTH_STYLE_HEIGHT,
	AMAZON_PAY_ELEMENT,
	AmazonPayAuthorization,
	AmazonPayProfile,
	initializeAmazonLogin,
	loadPwaButton,
	loadPwaWalletWidget,
	loginWithAmazon,
} from './Checkout.payment.utils';
import { Action, Dispatch } from 'redux';

declare global {
	interface Window {
		onAmazonPaymentsReady?: Function;
	}
}

export const fetchEstimatedTaxForVilt = (
	isVILT: boolean,
	dispatch: Dispatch,
	sellerOfRecordId: string | number,
	learningObjectId: string | number,
): void => {
	if (isVILT)
		dispatch(
			actions.checkoutFetchViltEstimatedTax({
				sellerOfRecordId,
				learningObjectId,
			}),
		);
};

/**
 * CheckoutPayment component
 * BEWARE: Gnarly business logic inside. For an overview of the payment pipeline
 * flow, see the `Checkout` component.
 */
const CheckoutPayment = ({
	awsSession,
	cartItem,
}: {
	awsSession: AwsSession;
	cartItem: CartItem;
}): ReactElement => {
	const { kind, price, resellingPlatform, sellerOfRecord } = awsSession;
	// Get helpers for actions and translations
	const { formatMessage } = useIntl();

	const audienceRoot: string = useSelector(audienceRootSelector) as string;

	const dispatch = useDispatch();

	// Prepare for special tax logic with VILTs
	const isVILT = kind === LEARNING_OBJECT_KINDS.VirtualIltSession;

	const isCheckoutServiceDisabled = useSelector(
		appSelectors.isFeatureDisabled('CheckoutService') as (
			state: DefaultRootState,
		) => boolean,
	);

	const { learningObjectId } = cartItem;

	// Verify if discount being used is 100% of the total
	const isFullDiscount: boolean = useSelector(
		isCheckoutServiceDisabled
			? isFullDiscountSelector
			: isFullDiscountSelectorForCheckoutService,
	);

	// Get login states
	const {
		profile,
	}: {
		authorization: AmazonPayAuthorization | null;
		profile: AmazonPayProfile | null;
	} = useSelector(loginSelector);
	const language: string = useSelector(languageSelector) as string;

	/**
	 * Indicates whether the learning object kind is VILT
	 *
	 * @param kind the learning object kind.
	 * @returns Returns {@code true} if the learning object kind is VILT
	 */
	const isKindVilt = (kind: number): boolean =>
		kind === LearningObjectKind.VirtualIltSession;

	/**
	 * Indicates whether the reselling platform is None (false) or any other valid value (true).
	 *
	 * @param resellingPlatform
	 * @returns Returns {@code true} if the reselling platform is valid and not 'None'.
	 */
	const isAuthorizedResellingPlatform = (resellingPlatform: number): boolean =>
		isNumber(resellingPlatform) &&
		Object.values(ResellingPlatform).includes(resellingPlatform) &&
		resellingPlatform !== ResellingPlatform.None;

	/**
	 * Indicates whether the currency is USD
	 *
	 * @returns Returns {@code true} if the currency is USD
	 */
	const isUsdCurrency = (currencyCode: string): boolean =>
		currencyCode === currencyCodes.USD;

	// Att: Normally, feature flags are used to disable/enable full routes in App.tsx.
	// Here we are using it to trigger components instead.
	const isXvoucherDisabled = useSelector(
		appSelectors.isFeatureDisabled('Xvoucher') as (
			state: DefaultRootState,
		) => boolean,
	);
	const isXvoucherOutageMessageDisabled = useSelector(
		appSelectors.isFeatureDisabled('XvoucherOutageMessage') as (
			state: DefaultRootState,
		) => boolean,
	);

	// Load PayWithAmazon widget
	const pwaConfig: ApiPwaConfig = useSelector(
		pwaConfigSelector,
	) as ApiPwaConfig;
	const domain = get(pwaConfig, 'logOnDomain', 'Amazon');
	const pwaRef = useRef<HTMLDivElement>(null);
	const paymentState: PaymentState = useSelector(stateSelector);

	const shouldShowAmazonPayWidget = false;

	/**
	 * Indicates whether the Xvoucher "Proceed to Payment" button should be displayed.
	 *
	 * Logic:
	 * - ILT
	 *   - true if
	 *     - feature flag XvoucherForILT is enabled AND
	 *     - class is sold by xvoucher
	 * - VILT
	 *   - true if
	 *     - class currency is non USD OR
	 *     - feature flag for Xvoucher is enabled AND
	 *     - class is sold by xvoucher
	 */
	const shouldShowXvoucher = (
		kind: number,
		sellerOfRecord: SellerOfRecord,
		resellingPlatform: number,
	): boolean => {
		return true; //deprecating Amazon Pay in favor of Xvoucher
	};

	useEffect(() => {
		if (
			language &&
			language === 'ja-jp' &&
			isVILT &&
			(!isKindVilt(kind) ||
				(isUsdCurrency(sellerOfRecord.currency.code) &&
					(!isAuthorizedResellingPlatform(resellingPlatform) ||
						isXvoucherDisabled)))
		) {
			dispatch(
				checkoutAlertAction({
					category: CheckoutAlertCategories.VILT_JP_WARNING,
					type: CheckoutAlertLevels.INFO,
				}),
			);
		}
	}, [
		dispatch,
		language,
		isVILT,
		isXvoucherDisabled,
		price,
		kind,
		sellerOfRecord.currency.code,
		resellingPlatform,
	]);

	// Get PayWithAmazon config based on learning object details
	useEffect(() => {
		const sellerOfRecordId = sellerOfRecord.id;

		if (shouldShowAmazonPayWidget) {
			dispatch(actions.checkoutFetchPwa({ sellerOfRecordId }));
		}
	}, [dispatch, sellerOfRecord.id, shouldShowAmazonPayWidget]);

	// Load captured order result and validate response.
	// If we have a captured order response (or are in the process of loading one)
	// any further interactions should be disabled and the appropriate alerts be shown.
	// Also, make sure tax is not loading when payment is submitted
	const isLoadingTax: boolean = useSelector(
		isLoadingKeySelector('tax') as (state: DefaultRootState) => boolean,
	);

	const isLoadingCapture: boolean = useSelector(
		isLoadingKeySelector('capture') as (state: DefaultRootState) => boolean,
	);

	// Load remote PayWithAmazon script and set up PayWithAmazon library ready hook
	const [isPwaLoaded, setIsPwaLoaded] = useState(false);
	const isPwaReady: boolean = useSelector(isPwaReadySelector);
	useEffect(() => {
		window.onAmazonPaymentsReady = (): Action<unknown> =>
			dispatch(actions.checkoutSetPwaReady(true));
	}, [dispatch]);
	const isPaymentReady = !!(
		((pwaConfig && isPwaLoaded && isPwaReady) ||
			shouldShowXvoucher(kind, sellerOfRecord, resellingPlatform)) &&
		!isLoadingCapture
	);

	useEffect(() => {
		if (!isPaymentReady) return;
		if (isFullDiscount) return;
		if (
			!(
				!isKindVilt(kind) ||
				(isUsdCurrency(sellerOfRecord.currency.code) &&
					(!isAuthorizedResellingPlatform(resellingPlatform) ||
						isXvoucherDisabled))
			)
		) {
			return;
		}
		switch (paymentState) {
			// Check that the PWA config has been loaded.
			// Then, load the AmazonPay Button and proceed to next states once the user has
			// successfully logged in.
			case PaymentState.INIT:
				if (shouldShowAmazonPayWidget) {
					initializeAmazonLogin(pwaConfig);
					loadPwaButton(pwaConfig.merchantId, pwaRef.current)
						.then(loginWithAmazon, error =>
							dispatch(
								actions.checkoutError({
									category: CheckoutAlertCategories.ERROR_SCRIPT_PWA_BUTTON,
									error,
								}),
							),
						)
						.then(
							response => {
								dispatch(actions.checkoutSetLogin(response));
								dispatch(actions.checkoutSetState(PaymentState.AUTH));
							},
							error =>
								dispatch(
									actions.checkoutError({
										category: CheckoutAlertCategories.ERROR_SCRIPT_AMAZON_LOGIN,
										error,
									}),
								),
						)
						.catch(error =>
							dispatch(
								actions.checkoutError({
									category: CheckoutAlertCategories.ERROR_UNKNOWN,
									error,
								}),
							),
						);
				}
				break;
			// Load the AmazonPay Wallet widget and enable the payment button and get new
			// tax values when the user selects a payment method.
			// The payment button is responsible for proceeding to the next step.
			case PaymentState.AUTH:
				const sellerOfRecordId = sellerOfRecord.id;
				if (pwaConfig) {
					loadPwaWalletWidget(
						{
							sellerId: pwaConfig.merchantId,
							onPaymentSelect: () => {
								fetchEstimatedTaxForVilt(
									isVILT,
									dispatch,
									sellerOfRecordId,
									learningObjectId,
								);
							},
							onOrderReferenceCreate: (orderReference: {
								readonly getAmazonOrderReferenceId: () => unknown;
							}) => {
								dispatch(
									actions.checkoutSetOrderReferenceId(
										orderReference.getAmazonOrderReferenceId(),
									),
								);
							},
							design: {
								designMode: 'responsive',
							},
							onError: (error: unknown) =>
								actions.checkoutError({
									category:
										CheckoutAlertCategories.ERROR_SCRIPT_PWA_WALLET_INTERNAL,
									error,
								}),
						},
						pwaRef.current,
					).catch(error =>
						dispatch(
							actions.checkoutError({
								category: CheckoutAlertCategories.ERROR_SCRIPT_PWA_WALLET,
								error,
							}),
						),
					);
					break;
				} else {
					break;
				}
		}
	}, [
		dispatch,
		isVILT,
		isFullDiscount,
		isPaymentReady,
		isXvoucherDisabled,
		kind,
		paymentState,
		price,
		pwaConfig,
		resellingPlatform,
		learningObjectId,
		sellerOfRecord.id,
		sellerOfRecord.currency.code,
		shouldShowAmazonPayWidget,
	]);

	const paymentTestid = isFullDiscount
		? 'CheckoutFullDiscountSubmit'
		: 'CheckoutPaymentSubmit';
	const paymentText = isFullDiscount
		? formatMessage({ ...registerButton })
		: formatMessage({ ...makePaymentButton });
	const capturedOrder = useSelector(capturedOrderSelector) as ApiCaptureOrder;

	useEffect(() => {
		if (capturedOrder) {
			if (capturedOrder.data.isSuccessful) {
				// Xvoucher payment status codes are different
				// than the expected payment status codes.
				// If successful, set its code to the normal
				// success code.
				capturedOrder.data.paymentStatusCode = PaymentStatusCode.SUCCESS;
			}
			switch (
				parseInt(
					(capturedOrder.data.paymentStatusCode as unknown) as string,
					10,
				) as PaymentStatusCode
			) {
				// success scenarios
				case PaymentStatusCode.SUCCESS:
					dispatch(actions.checkoutSetState(PaymentState.PAID));
					dispatch(
						checkoutAlertAction({
							category:
								!isKindVilt(kind) ||
								(isUsdCurrency(sellerOfRecord.currency.code) &&
									(!isAuthorizedResellingPlatform(resellingPlatform) ||
										isXvoucherDisabled))
									? CheckoutAlertCategories.SUCCESS
									: CheckoutAlertCategories.XVOUCHER_SUCCESS,
							type: CheckoutAlertLevels.SUCCESS,
							data: capturedOrder,
						}),
					);
					break;
				case PaymentStatusCode.ASYNC_AUTHORIZATION_INITIATED:
					dispatch(actions.checkoutSetState(PaymentState.PAID));
					dispatch(
						checkoutAlertAction({
							category: CheckoutAlertCategories.SUCCESS_ASYNC_INIT,
							type: CheckoutAlertLevels.SUCCESS,
							data: capturedOrder,
						}),
					);
					break;
				case PaymentStatusCode.PAYMENT_NOT_NEEDED:
					dispatch(actions.checkoutSetState(PaymentState.PAID));
					dispatch(
						checkoutAlertAction({
							category: CheckoutAlertCategories.SUCCESS_PAYMENT_NOT_NEEDED,
							type: CheckoutAlertLevels.SUCCESS,
							data: capturedOrder,
						}),
					);
					break;
				// ensure no error is shown for these two cases
				case PaymentStatusCode.REDIRECT_TO_XVOUCHER:
				case PaymentStatusCode.ASYNC_AUTHORIZATION_DECLINED &&
					!(
						!isKindVilt(kind) ||
						(isUsdCurrency(sellerOfRecord.currency.code) &&
							(!isAuthorizedResellingPlatform(resellingPlatform) ||
								isXvoucherDisabled))
					):
					break;
				// failure scenarios
				default:
					dispatch(
						actions.checkoutError({
							category: CheckoutAlertCategories.ERROR_CAPTURE_RESPONSE,
							data: capturedOrder,
							error: 'Purchase rejected by API.',
						}),
					);

					break;
			}
		}
	}, [
		dispatch,
		capturedOrder,
		audienceRoot,
		price,
		isXvoucherDisabled,
		kind,
		sellerOfRecord.currency.code,
		resellingPlatform,
		isFullDiscount,
	]);
	const hasCapturedOrder = !!capturedOrder;
	const canCapture = !(isLoadingTax || isLoadingCapture || hasCapturedOrder);

	const isVoucherInvalid = useSelector(isVoucherInvalidSelector) as boolean;

	return (
		<section data-testid="CheckoutPayment">
			{pwaConfig ? (
				<Script
					onLoad={(): void => setIsPwaLoaded(true)}
					url={pwaConfig.widgetJsUrl}
				/>
			) : null}

			<hr className={styles.hr} />

			<Loader
				data-test-hasloaded={isPaymentReady.toString()}
				data-testid="CheckoutPaymentLoaderSection"
				hasLoaded={isPaymentReady}
				variant={LoaderConfig.SectionVariant}
			>
				{!isKindVilt(kind) ||
				(isUsdCurrency(sellerOfRecord.currency.code) &&
					(!isAuthorizedResellingPlatform(resellingPlatform) ||
						isXvoucherDisabled)) ? (
					<Fragment>
						{/* estimatedTaxHelp message is only shown with Amazon Pay */}
						{paymentState === PaymentState.INIT && isVILT && !isFullDiscount ? (
							<p data-testid="CheckoutPaymentEstimatedTaxInstructions">
								<FormattedMessage {...estimatedTaxHelp} />
							</p>
						) : null}

						{paymentState === PaymentState.INIT &&
						!isVILT &&
						!isFullDiscount ? (
							<p data-testid="CheckoutPaymentLoginInstructions">
								<FormattedMessage
									{...signInButton}
									values={{
										domain,
									}}
								/>
							</p>
						) : null}

						{paymentState === PaymentState.AUTH && !isFullDiscount ? (
							<div data-testid="CheckoutPaymentWalletInstructions">
								<p>
									<FormattedMessage
										{...currentAccountMessage}
										values={{
											domain,
											email: (
												<strong>
													{(profile as AmazonPayProfile).PrimaryEmail || ''}
												</strong>
											),
											name: (
												<strong>
													{(profile as AmazonPayProfile).Name || ''}
												</strong>
											),
										}}
									/>
								</p>
								<p>
									<FormattedMessage
										{...walletPaymentMethod}
										values={{
											buttonText: formatMessage({ ...makePaymentButton }),
										}}
									/>
								</p>
							</div>
						) : null}

						{paymentState !== PaymentState.PAID && !isFullDiscount ? (
							<div
								className={styles.widget}
								data-testid="CheckoutPaymentWidget"
								id={AMAZON_PAY_ELEMENT}
								ref={pwaRef}
								role={'presentation'}
								style={
									paymentState === PaymentState.AUTH
										? { height: AMAZON_PAY_AUTH_STYLE_HEIGHT }
										: {}
								}
							/>
						) : null}
					</Fragment>
				) : null}
				{shouldShowXvoucher(kind, sellerOfRecord, resellingPlatform) ? (
					<Fragment>
						{paymentState === PaymentState.INIT &&
						!isLoadingCapture &&
						!isFullDiscount ? (
							<p data-testid="CheckoutPaymentXvoucherEstimatedTaxInstructions">
								<FormattedMessage {...xvoucherEstimatedTaxHelp} />
							</p>
						) : null}
						{!isLoadingCapture &&
						paymentState !== PaymentState.PAID &&
						!isFullDiscount ? (
							<Fragment>
								<Button
									className={styles.buttonSubmitPayment}
									text={formatMessage({ ...xvoucherProceedToPayment })}
									size="large"
									variant="primary"
									data-testid="paywithxvoucher"
									icon="chevron-right"
									iconAlign="right"
									disabled={isVoucherInvalid}
									onClick={(): void => {
										dispatch(
											actions.checkoutPostCaptureXvoucher({
												learningObjectId,
												isXvoucherOutageMessageDisabled,
											}),
										);
									}}
								/>
								<p data-testid="CheckoutPaymenXvoucherRedirectNotice">
									<FormattedMessage {...xvoucherRedirect} />
								</p>
							</Fragment>
						) : null}
					</Fragment>
				) : null}
				{/* Regardless of payment method, show this button for full discounts */}
				{paymentState === PaymentState.AUTH ||
				(isFullDiscount && paymentState !== PaymentState.PAID) ? (
					<Button
						className={styles.buttonSubmitPayment}
						disabled={(!isFullDiscount && !canCapture) || isVoucherInvalid}
						data-testid={paymentTestid}
						onClick={(): Action<unknown> | null =>
							isFullDiscount || canCapture
								? dispatch(actions.checkoutPutCapture({ learningObjectId }))
								: null
						}
						size="large"
						text={paymentText}
						variant="primary"
					/>
				) : null}
			</Loader>
		</section>
	);
};

export default CheckoutPayment;
